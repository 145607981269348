import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiUrl } from '../config'
import cogoToast from 'cogo-toast'
import { redirect } from 'react-router-dom'


const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  }


//get pension table
export const AdminRequestTable = createAsyncThunk(
    'AdminRequestTable',
    async({page, page_size}, {rejectWithValue, dispatch}) => {
     try {
        const res = await axios.get(`${apiUrl}admin/advance-requests/?page_size=${page_size}&page=${page}`,
        {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}` 
            }
        }
        )
    if(res.status === 200){
        return res.data
    }
    
  } catch (error) {
    if(error.response.status === 401){
      cogoToast.error("Authentication credentials were not provided.")
      dispatch({type: 'auth/Logout'})
      redirect('/login')
    }
    else{
      return rejectWithValue(error.response)
    }
  }
    }
)

//get payment table
export const AdminPaymentTable = createAsyncThunk(
    'AdminPaymentTable',
    async({page, page_size}, {rejectWithValue, dispatch}) => {
     try {
        const res = await axios.get(`${apiUrl}admin/advance-payments/?page_size=${page_size}&page=${page}`,
        {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}` 
            }
        }
        )
    if(res.status === 200){
        return res.data
    }
    
  } catch (error) {
    if(error.response.status === 401){
      cogoToast.error("Authentication credentials were not provided.")
      dispatch({type: 'auth/Logout'})
      redirect('/login')
    }
    else{
      return rejectWithValue(error.response)
    }
  }
    }
)