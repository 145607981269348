import React, { useState } from 'react'
import Header from '../../components/header';
//import { useDispatch, useSelector } from "react-redux";

const SalaryAdvancePage = () => {
    // const { loading } = useSelector(
    //     (state) => state.salary_advance
    // );

    const [tab, setTab] = useState("Schedule");
    //const dispatch = useDispatch();



    //logic for the modal component to download a schedule
    //const [insertDate, setInsertDate] = useState(false);
    //const [contributionDate, setContributionDate] = useState(false);

    // const toggleInsertDate = () => {
    //     setInsertDate(!insertDate);
    // };

    //   const handleDownloadSchedule = () => {
    //     setInsertDate(false);
    //     dispatch(downloadPensionSchedule({
    //       contribution_date: moment(contributionDate).format("YYYY-MM-DD"),

    //     }))

    //   };

    return (
        <>
            {/* <ModalComponent
        title="Download Pension Schedule"
        show={insertDate}
        showModal={() => {
          setInsertDate(false);
        }}
        subtitle=""
      >
        <div>
          <div className="flex flex-col  gap-6 mt-4">
            <div className='items-left'>
              <p className="text-secondary text-sm font-normal">
                Enter the date of schedule to be downloaded
              </p>
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                name="payslipDate"
                value={contributionDate}
                onChange={((e) => setContributionDate(e.target.value))}
                className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm"
              />
            </div>
          </div>
          <div className="mt-4 flex justify-center align-center items-center "
          >
            <button
              onClick={handleDownloadSchedule}
              className="outline-none rounded-lg disabled:text-[#ABB6AC] disabled:bg-[#ABB6AC] bg-primary text-white py-3 px-5  text-sm font-medium border border-primary hover:bg-[#24752B] hover:bg-opacity-[0.9]"
            >
              Download
            </button>
          </div>
        </div>
      </ModalComponent> */}
            <Header
                text="Salary Advance"
                tabs={
                    <div className="flex gap-[16px] mt-[16px]">
                        <p
                            className={
                                tab === "Payments"
                                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                            }
                            onClick={() => setTab("Payments")}
                        >
                            Payments
                        </p>
                        <p
                            className={
                                tab === "Requests"
                                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                            }
                            onClick={() => setTab("Requests")}
                        >
                            Requests
                        </p>
                    </div>
                }
            >
            </Header>
            {/* {tab === "Payments" &&
                (
                    <SalaryAdvancePaymentTable />
                )}

            {tab === 'Requests' && (
                <SalaryAdvanceRequestTable />
            )} */}
        </>
    );
}

export default SalaryAdvancePage;