import { createSlice } from '@reduxjs/toolkit'
import { AdminRequestTable, AdminPaymentTable } from './advanceAction'

const initialState = {
    value: 0,
    total: 0,
    loading: false,
    loader: false,
    requests: [],
    total_advance: 0,
    total_request: 0,
    payments: []
}

export const advanceSlice = createSlice({
    name: 'advance',
    initialState,
    reducers: {
        decrement: (state) => {
            state.value -= 1
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload
        },
    },
    extraReducers: {
        //get admin payment table --------------
        [AdminPaymentTable.pending]: (state) => {
            state.loading = true
            state.loader = true
        },
        [AdminPaymentTable.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.loader = false
            state.total_payments = payload.data.count
            state.payments = payload.data
        },
        [AdminPaymentTable.rejected]: (state) => {
            state.loading = false
            state.loader = false
        },
        //get admin request table --------------
        [AdminRequestTable.pending]: (state) => {
            state.loading = true
            state.loader = true
        },
        [AdminRequestTable.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.loader = false
            state.total_request = payload.data.count
            state.requests = payload.data
        },
        [AdminRequestTable.rejected]: (state) => {
            state.loading = false
            state.loader = false
        },
    }
})

// Action creators are generated for each case reducer function
export const { decrement, incrementByAmount } = advanceSlice.actions
export default advanceSlice.reducer
